[role="tablist"] {
  @apply flex flex-row gap-3;
}

[role="menu"] {
  @apply bg-white shadow-lg border border-slate-100 min-w-48 rounded;
}

[role="menuitem"] {
  @apply block px-3 py-2 text-sm font-normal no-underline shadow-none cursor-pointer data-[focus]:bg-slate-100;
}

/* React Select */
.css-13cymwt-control {
  @apply !border-gray-200;
}

.input-sm.css-b62m3t-container {
  @apply p-0;
}

.input-sm .css-13cymwt-control, .input-sm .css-t3ipsp-control {
  @apply min-h-[26px];
}

.input-sm .css-1xc3v61-indicatorContainer, .input-sm .css-15lsz6c-indicatorContainer {
  @apply p-0;
}

.input-sm .css-tj5bde-Svg {
  @apply scale-75;
}

.input-sm .css-d7l1ni-option,
.input-sm .css-tr4s17-option,
.input-sm .css-10wo9uf-option {
  @apply !text-[13px];
}

/* focus */
.css-t3ipsp-control {
  @apply ring-1 ring-slate-300 !border-slate-300  !shadow-none;
}

/* option hovered, selected */
.css-d7l1ni-option {
  @apply !bg-slate-100;
}

/* selected */ 
.css-tr4s17-option {
  @apply !bg-blue-700;
}

.css-tr4s17-option > * {
  @apply !text-white;
}

/* option item */
.css-d7l1ni-option,
.css-tr4s17-option,
.css-10wo9uf-option {
  @apply !text-[15px];
}

/* placeholder */
.css-1jqq78o-placeholder {
  @apply !text-gray-400;
}

/* React Datepicker */
.react-datepicker {
  @apply border-slate-200 shadow-lg;
}

.react-datepicker-popper .react-datepicker__triangle {
  @apply stroke-slate-200;
}

.react-datepicker__header {
  @apply bg-gray-100 border-slate-200;
}

.react-datepicker__navigation {
  @apply shadow-none;
}

.react-datepicker__day--selected {
  @apply bg-blue-700 hover:bg-blue-700;
}

.date-number-picker-input {
  @apply w-16;
}

.calendar-date-number-picker .react-datepicker__day-names,
.calendar-date-number-picker .react-datepicker__day--outside-month,
.calendar-date-number-picker .react-datepicker__header {
  @apply hidden;
}

.calendar-date-number-picker .react-datepicker__month {
  @apply text-left;
}

.calendar-month-date-picker .react-datepicker__day-names {
  @apply hidden;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  @apply rounded-sm;
  background-color: #f1f5f9;
}

*::-webkit-scrollbar-thumb {
  @apply rounded;
  background-color: #cbd5e1;
}

.navlink-tab {
  @apply inline-block no-underline py-1.5 px-4 text-sm rounded-full shadow-none hover:bg-slate-50 border;
}

.navlink-tab.active {
  @apply bg-blue-50 text-blue-500 border-blue-500;
}

/* React select country, state, city */
.stsearch-box {
  @apply relative;
}

.stsearch-box input {
  @apply w-full;
}

.stdropdown-tools {
  @apply absolute right-2 top-3;
}

.stdropdown-tools svg {
  @apply fill-slate-500;
}

.stdropdown-menu {
  @apply absolute translate-y-1 bg-white shadow-lg border border-slate-100 min-w-48 rounded max-h-40 overflow-auto z-10;
}

.stdropdown-item {
  @apply flex gap-1 items-center px-3 py-2 text-[15px] font-normal no-underline shadow-none cursor-pointer hover:bg-slate-100;
}

/* Phone input */
.PhoneInput .PhoneInputCountry {
  @apply hidden;
}

.popover-panel {
  @apply py-2 px-4 bg-white border rounded text-sm shadow-lg;
}