@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-neutral-800;
  }

  a, .link {
    @apply underline decoration-blue-200 decoration-2 underline-offset-4 hover:no-underline cursor-pointer font-medium;
  }

  a.active {
    @apply text-blue-700 font-medium;
  }

  button, input, textarea, select {
    @apply py-2 rounded text-[15px];
  }

  button {
    @apply px-6 font-medium shadow;
  }

  button[role="tab"] {
    @apply py-1.5 px-4 text-sm rounded-full shadow-none hover:bg-slate-50 border data-[selected]:bg-blue-50 data-[selected]:text-blue-500 data-[selected]:border-blue-500 data-[selected]:outline-none;
  }

  button[aria-haspopup="menu"] {
    @apply p-0 shadow-none;
  }

  label {
    @apply text-[15px] text-slate-500;
  }

  input, textarea, select {
    @apply px-4 border focus:outline-slate-300;
  }

  select {
    @apply block border bg-white px-3 py-2.5;
  }

  th {
    @apply font-normal text-left text-slate-500;
  }
}

@layer components {
  @import './styles/button.css';
  @import './styles/input.css';
  @import './styles/form.css';
  @import './styles/others.css';
}