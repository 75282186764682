.btn-primary {
  @apply text-white bg-blue-700 hover:bg-blue-600 hover:shadow-none disabled:opacity-50 disabled:hover:bg-blue-700 focus:outline-blue-800;
}

.btn-secondary {
  @apply text-blue-500 bg-blue-50 border border-blue-200 hover:shadow-none disabled:opacity-50 disabled:shadow-none;
}

.btn-tertiary {
  @apply border border-slate-200 text-slate-600 hover:shadow-none disabled:opacity-50 disabled:shadow-none;
}

.btn-danger {
  @apply text-white bg-red-500 hover:bg-red-600 disabled:opacity-50 disabled:hover:bg-red-500 focus:outline-red-800;
}

.btn-warning {
  @apply text-white bg-orange-500 hover:bg-orange-600 disabled:opacity-50 disabled:hover:bg-orange-500 focus:outline-orange-800;
}

.btn-success {
  @apply text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:hover:bg-green-600 focus:outline-green-800;
}

.btn-sm {
  @apply py-1 px-3 text-xs rounded shadow-sm;
}

.btn-link {
  @apply px-5 py-2 rounded text-[15px] shadow no-underline;
}